<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden" style="position: relative">
      <NavBar tittle="label.lbl_billingCutOffProcess" />
      <!-- 月締め処理画面 -->
      <v-form ref="form">
        <v-container fill-height>
          <v-row style="height: calc(100vh - 48px)" justify="center" align-content="center">
            <v-card width="600px" class="mx-auto mt-5">
              <v-card-text>
                <p style="font-size: large; font-weight: bold; text-align: center">
                  {{ $t("label.lbl_billingCutOffProcess") }}
                </p>
                <v-row class="search-row">
                  <div class="search-row-exeBtn mt-">
                    <!-- 取引先-->
                    <div
                      class="search-autocomplete first-search-item mb-5"
                      style="float: left; width: 450px"
                    >
                      <v-autocomplete
                        dense
                        v-model="suppliersSelected"
                        :items="supplierList"
                        :label="$t('label.lbl_supplier')"
                        :hint="setSrhSupplier()"
                        class="txt-single"
                        persistent-hint
                        :rules="[rules.supplierRequired]"
                        :error-messages="alertSupplierMessage"
                      ></v-autocomplete>
                    </div>
                    <span class="require asterisk-spacer" style="float: left">*</span>
                  </div>
                </v-row>

                <v-row>
                  <!-- 請求月度 -->
                  <!-- カレンダー -->
                  <div class="date-style" style="float: left; margin-left: 12px">
                    <v-text-field
                      outlined
                      dense
                      v-model="billingCutOffMonth"
                      :label="$t('label.lbl_billingMonth')"
                      @change="changeDate"
                      :rules="[rules.billingCutOffMonthRequired, rules.yyyymmdd]"
                      class="txt-single"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <span class="require asterisk-spacer" style="float: left">*</span>
                  <!--カレンダーボタン領域-->
                  <div class="calender-btn-area" style="float: left">
                    <v-menu
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      :nudge-right="-100"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      class="date-calendar"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text x-small>
                          <v-icon>mdi-calendar</v-icon>
                        </v-btn>
                      </template>
                      <v-date-picker
                        v-model="billingCutOffMonthCal"
                        @input="dateMenu = false"
                        type="month"
                      >
                      </v-date-picker>
                    </v-menu>
                    <v-btn
                      id="btn-yesterday"
                      class="day-ctrl-btn"
                      @click="prevDateFrom"
                      text
                      x-small
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDateFrom" text x-small>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                  <!-- 期-->
                  <div class="search-autocomplete first-search-item mb-5" style="float: left">
                    <v-autocomplete
                      dense
                      v-model="periodSelected"
                      :items="periodList"
                      :label="$t('label.lbl_period')"
                      :hint="setPeriod()"
                      class="txt-single"
                      persistent-hint
                    >
                      <template slot="item" slot-scope="data">
                        <span class="auto-txt-size"> {{ data.item.text }} </span>
                      </template>
                    </v-autocomplete>
                  </div>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-col>
                  <div
                    class="text-label"
                    style="float: left; font-size: larger; font-weight: normal"
                  >
                    <span class="notice">最終月締めの月度・期：{{ date }}</span>
                  </div>

                  <!-- 空白 -->
                  <span class="item-spacer" style="float: left">&nbsp;</span>

                  <div style="float: right">
                    <!-- 実行ボタン -->
                    <v-btn
                      ref="confirmBtn"
                      color="primary"
                      id="btn-content-confirm"
                      class="other-btn"
                      right
                      @click="registration"
                      >{{ $t("btn.btn_execute") }}</v-btn
                    >
                  </div>
                </v-col>
              </v-card-actions>
              <v-card-actions>
                <v-col>
                  <div style="float: right">
                    <!-- 解除ボタン -->
                    <v-btn
                      ref="confirmBtn"
                      color="primary"
                      id="btn-content-confirm"
                      class="other-btn"
                      right
                      @click="rescission"
                      >{{ $t("btn.btn_rescission") }}</v-btn
                    >
                  </div>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-row>
        </v-container>
        <SimpleDialog
          :isShow.sync="infoDialog.isOpen"
          :title="infoDialog.title"
          :message="infoDialog.message"
          :closeFlag="infoDialog.closeFlag"
          :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        />
        <ConfirmDialog
          :isShow.sync="ConfirmDialog.isOpen"
          :message="ConfirmDialog.message"
          :okAction="ConfirmDialog.okAction"
          :redMessage="ConfirmDialog.redMessage"
          :screenFlag="ConfirmDialog.screenFlag"
          :changeFlag="ConfirmDialog.changeFlag"
        />
      </v-form>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import NavBar from "../../components/NavBar.vue";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { i18n } from "../../lang/lang.js";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: appConfig.MENU_ID.P_BIL_CUT_OFF_MONTH_REGISTRATION,
  components: {
    Loading,
    sideMenu,
    SimpleDialog,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_10: false,
    alertMessage: "",
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      closeFlag: false,
      outsideClickNotCloseFlg: false,
    },
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 月度
    billingCutOffMonth: "",
    billingCutOffMonthCal: "",
    // 取引先
    suppliersSelected: "",
    supplierList: [],
    // 期
    periodSelected: "",
    periodList: [{ text: "", value: "" }],
    alertSupplierMessage: "",
    //最終月度取得用
    yyyyMm: "",
    period: "",
    date: "",
    yyyy: "",
    mm: "",

    // メニュー
    openMenu: null,
    // 入荷予定日付カレンダー
    dateMenu: false,
    rules: {
      billingCutOffMonthRequired: (value) =>
        !!value || i18n.tc("label.lbl_billingMonth") + i18n.tc("check.chk_input_variable"),
      yyyymmdd: (value) =>
        value == null ||
        (!!value &&
          value.length == 7 &&
          !!value.match(/^[0-9]{4}\/(0[1-9]|1[0-2])$/) &&
          new Date(value.split("/")[0] + "-" + value.split("/")[1]) != "Invalid Date") ||
        i18n.tc("check.chk_billingCutOffMonth"),
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
    },
  }),
  methods: {
    // 初期化
    init() {
      this.getSupplierList();
      this.billingCutOffMonthCal = dateTimeHelper.dateCalcYyyyMM(
        dateTimeHelper.convertJstDateTime(new Date())
      );
    },
    // カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
    formatDate(date) {
      if (!date) return null;
      date = commonUtil.zen2han(date);
      // 月の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|[1-9]|1[0-2])$/;
      date = date.replaceAll("／", "/");
      // 対象の文字列チェック
      if (regex.test(date)) {
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month] = date.split("-");
        return `${year}/${month}`;
      }
    },
    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 6) {
        strDt = inputVal.substr(0, 4) + "-" + inputVal.substr(4, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "-" + inputVal.substr(5, 2);
      }
      if (strDt != null && strDt.length == 7 && new Date(strDt) != "Invalid Date") {
        this.billingCutOffMonthCal = strDt;
      } else if (this.isEmpty(val)) {
        this.billingCutOffMonthCal = null;
      } else {
        this.billingCutOffMonthCal = null;
      }
    },

    // 項目が空かチェック
    isEmpty(value) {
      if (value === undefined || value === null || value === "") {
        return true;
      }
      return false;
    },

    prevDateFrom() {
      if (this.billingCutOffMonthCal == null || this.billingCutOffMonth == null) {
        return;
      }
      let calDate = new Date(this.billingCutOffMonthCal);
      const [year, month] = this.billingCutOffMonth.split("/");
      let inputDate = new Date(`${year}-${month}`);
      if (isNaN(inputDate.getMonth())) {
        this.billingCutOffMonthCal = null;
        return;
      }
      if (calDate.getMonth() == inputDate.getMonth()) {
        this.billingCutOffMonthCal = dateTimeHelper.dateCalcYyyyMM(
          calDate.setMonth(calDate.getMonth() - 1)
        );
      } else {
        this.billingCutOffMonthCal = dateTimeHelper.dateCalcYyyyMM(
          inputDate.setMonth(inputDate.getMonth() - 1)
        );
      }
    },

    nextDateFrom() {
      if (this.billingCutOffMonthCal == null || this.billingCutOffMonth == null) {
        return;
      }
      let calDate = new Date(this.billingCutOffMonthCal);
      const [year, month] = this.billingCutOffMonth.split("/");
      let inputDate = new Date(`${year}-${month}`);
      if (isNaN(inputDate.getMonth())) {
        this.billingCutOffMonthCal = null;
        return;
      }
      if (calDate.getMonth() == inputDate.getMonth()) {
        this.billingCutOffMonthCal = dateTimeHelper.dateCalcYyyyMM(
          calDate.setMonth(calDate.getMonth() + 1)
        );
      } else {
        this.billingCutOffMonthCal = dateTimeHelper.dateCalcYyyyMM(
          inputDate.setMonth(inputDate.getMonth() + 1)
        );
      }
    },
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    jumpTo() {},

    /**
     * 締め月のチェック
     */
    validMonth(strDate, prefix) {
      if (strDate != undefined && strDate != "" && (strDate.length == 6 || strDate.length == 7)) {
        var ymd = strDate.split(prefix);
        if (new Date(ymd[0] + "-" + ymd[1]) != "Invalid Date") {
          return true;
        }
        return false;
      }
    },

    // 取引先コードリストボックス取得処理
    setSrhSupplier() {
      for (let i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    // 期リストボックス取得処理
    setPeriod() {
      for (let i = 0; i < this.periodList.length; i++) {
        if (this.periodList[i].value == this.periodSelected) {
          return this.periodList[i].name;
        }
      }
    },
    // 取引先API接続
    getSupplierList(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate),
        "1"
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          // 取引先が1件の場合、取引先を自動選択する
          this.supplierList.length == 1
            ? (this.suppliersSelected = this.supplierList[0].value)
            : "";
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 最終登録日の取得
    getLastDate() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 最終登録日APIを呼び出し
      const dateList = getParameter.getMaxMonth(this.suppliersSelected);
      // 実行結果
      Promise.all([dateList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.lastDateList = result[0];
          // 月度
          this.yyyy = result[0].yyyyMm.substring(0, 4);
          this.mm = result[0].yyyyMm.substring(4, 6);
          this.yyyyMm = this.yyyy + "/" + this.mm;

          // this.yyyyMm = result[0].yyyyMm;
          // 期
          if (result[0].yyyyMm != "") {
            if (result[0].periodDiv == "01") {
              this.period = "1期";
            } else if (result[0].periodDiv == "02") {
              this.period = "2期";
            } else {
              this.period = "3期";
            }
            this.date = this.yyyyMm + "・" + this.period;
          } else {
            this.date = "";
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 期API接続
    getPeriodList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // APIパラメータ
      const config = this.$httpClient.createGetApiRequestConfig();
      // 営業所SID
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先SID
      config.params.clientSid = this.suppliersSelected;
      // マスタ取得区分
      config.params.isMst = "0";

      // console.debug("getPeriodList() Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_BUSINESS, config)
          .then((response) => {
            // console.debug("getPeriodList() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [{ text: "", value: "" }];
              jsonData.resIdv.businesses.forEach((row) => {
                // if (row.businesses.length > 0) {
                if (row.whatPeriod == "01") {
                  this.periodSelected = "";
                  list.push({
                    text: "1期",
                    value: "01",
                  });
                } else if (row.whatPeriod == "02") {
                  this.periodSelected = "";
                  list.push(
                    {
                      text: "1期",
                      value: "01",
                    },
                    {
                      text: "2期",
                      value: "02",
                    }
                  );
                } else {
                  this.periodSelected = "";
                  list.push(
                    {
                      text: "1期",
                      value: "01",
                    },
                    {
                      text: "2期",
                      value: "02",
                    },
                    {
                      text: "3期",
                      value: "03",
                    }
                  );
                }
              });
              this.periodList = list;
              this.periodList.sort();
              resolve("期の取得APIレスポンス", response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 実行ボタン押下時の処理
     */
    registration() {
      if (this.$refs.form.validate()) {
        this.ConfirmDialog.message = messsageUtil.getMessage("P-BIL-003_001_C");
        this.ConfirmDialog.title = appConfig.DIALOG.title;
        this.ConfirmDialog.isOpen = true;
        this.ConfirmDialog.screenFlag = true;
        this.ConfirmDialog.changeFlag = false;
        this.ConfirmDialog.okAction = this.registrationOnOkClick;
      }
    },

    /**
     * 実行処理のPOSTAPI接続
     */
    registrationOnOkClick() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // APIパラメータ
      const body = this.$httpClient.createRequestBodyConfig();
      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_BIL_003;

      // 営業所SID
      body.reqCom.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先SID
      body.reqIdv.clientSid = this.suppliersSelected;
      // 月度
      body.reqIdv.yyyyMm = this.billingCutOffMonth.replaceAll("/", "");
      // 期
      body.reqIdv.periodDiv = this.periodSelected;
      // TimeZone
      body.reqIdv.timeZone = "JST";
      // console.debug("registrationOnOkClick() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BILL_REGISTRATION, body, appConfig.APP_CONFIG)
          // .securePost("business/period-stock-entry", body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("registrationOnOkClick() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常の場合
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              if (jsonData.resIdv.status == 0) {
                this.infoDialog.message = messsageUtil.getMessage("P-BIL-003_005_E");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.itemsMstFlg = true;
                this.infoDialog.closeFlag = true;
                this.infoDialog.outsideClickNotCloseFlg = true;
                resolve(response);
              } else {
                this.infoDialog.message = messsageUtil.getMessage("P-BIL-003_002_E");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.itemsMstFlg = true;
                this.infoDialog.closeFlag = true;
                this.infoDialog.outsideClickNotCloseFlg = true;
                this.getLastDate();
                resolve(response);
              }
            } else {
              // エラーの場合
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.itemsMstFlg = true;
              this.infoDialog.closeFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 解除ボタン押下の処理
     */
    rescission() {
      if (this.$refs.form.validate()) {
        this.ConfirmDialog.message = messsageUtil.getMessage("P-BIL-003_003_C");
        this.ConfirmDialog.title = appConfig.DIALOG.title;
        this.ConfirmDialog.isOpen = true;
        this.ConfirmDialog.screenFlag = true;
        this.ConfirmDialog.changeFlag = false;
        this.ConfirmDialog.okAction = this.rescissionOnOkClick;
      }
    },
    /**
     *  解除処理のPOSTAPI接続
     */
    rescissionOnOkClick() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // APIパラメータ
      const body = this.$httpClient.createRequestBodyConfig();

      // 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_BIL_003;
      // 営業所SID
      body.reqCom.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先SID
      body.reqIdv.clientSid = this.suppliersSelected;
      // 月度
      body.reqIdv.yyyyMm = this.billingCutOffMonth.replaceAll("/", "");
      // 期
      body.reqIdv.periodDiv = this.periodSelected;

      // console.debug("rescissionOnOkClick() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URL
          .securePost(appConfig.API_URL.BILL_RESCISSION, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("rescissionOnOkClick() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              if (jsonData.resIdv.status == 0) {
                this.infoDialog.message = messsageUtil.getMessage("P-BIL-003_005_E");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.itemsMstFlg = true;
                this.infoDialog.closeFlag = true;
                this.infoDialog.outsideClickNotCloseFlg = true;
                resolve(response);
              } else {
                this.infoDialog.message = messsageUtil.getMessage("P-BIL-003_004_E");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.closeFlag = true;
                this.infoDialog.outsideClickNotCloseFlg = true;
                this.getLastDate();
                resolve(response);
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.closeFlag = true;
              this.infoDialog.outsideClickNotCloseFlg = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    billingCutOffMonthCal(val) {
      this.billingCutOffMonth = this.formatDate(val);
    },
    // suppliersSelected(val) {
    //   this.supplierRequired = this.billingCutOffMonth(val);
    // },
    suppliersSelected(newValue) {
      if (newValue || null) {
        this.suppliersSelected = newValue;
        this.getPeriodList();
        this.getLastDate();
      } else {
        this.suppliersSelected = newValue;
        this.getPeriodList();
        this.getLastDate();
      }
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.from_to {
  font-weight: bold;
  // padding-top: 8px;
  // margin-left: 0;
  // margin-right: 0;
}

.notice {
  margin-left: 10px;
}

// テーブルフッターの高さ調整
// .minheight {
//   margin-bottom: 0px;
// }
</style>
